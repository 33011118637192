import React from 'react';
import { Answer, ConInformative, Condition } from "../models/AnswerModel";
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import DropdownInput from './forms/DropdownInput';
import DeleteIcon from '@mui/icons-material/Delete';
import TextInput from './forms/TextInput';
import { getAnswersDropDowns, getQuestionsDropDown } from '../data/QuestionAndAnswerService';

interface SharedComponentProps {
    data: Answer | ConInformative;
    onHandleConditionKeyChange: (value: string, conditionIndex: number) => void;
    onHandleConditionValueChange: (value: string, conditionIndex: number) => void;
    onHandleConditionRemove: (conditionIndex: number) => void;
}

const ConditionalLineComponent: React.FC<SharedComponentProps> = ({ data, onHandleConditionKeyChange, onHandleConditionValueChange, onHandleConditionRemove }) => {
    if (data.Type === 'NA') {
        return (<></>);
    }

    const handleConditionKeyChange = (value: string, conditionIndex: number) => {
        onHandleConditionKeyChange(value, conditionIndex);
    };
    const handleConditionValueChange = (value: string, conditionIndex: number) => {
        onHandleConditionValueChange(value, conditionIndex);
    };
    const handleConditionRemove = (conditionIndex: number) => {
        onHandleConditionRemove(conditionIndex);
    };

    const renderResponse = (condition: Condition, conditionIndex: number) => {
        if (condition.Key in getAnswersDropDowns()) {
            return (<DropdownInput label='Value' fieldName={`Value`} value={condition.Value} items={getAnswersDropDowns()[condition.Key]} onChange={(value) => { handleConditionValueChange(value, conditionIndex) }} />);
        }
        else {
            return (<TextInput fieldName={'value'} value={condition.Value} onChange={(value) => handleConditionValueChange(value, conditionIndex)} />);
        }
    }
    return (
        <Box sx={{ m: 2 }}>
            <Typography>Conditions</Typography>
            <Stack spacing={2}>
                {data.Conditions.map((condition, conditionIndex) => (
                    <Grid container spacing={2} key={conditionIndex}>
                        <Grid item xs={4}>
                            <DropdownInput label='Key' fieldName={`key`} value={condition.Key} items={getQuestionsDropDown()} onChange={(value) => handleConditionKeyChange(value, conditionIndex)} />
                        </Grid>
                        <Grid item xs={7}>
                            {renderResponse(condition, conditionIndex)}
                        </Grid>
                        <Grid item xs={1} className='actions-bar'>
                            <Button color='error' onClick={() => handleConditionRemove(conditionIndex)}> <DeleteIcon /></Button>
                        </Grid>
                    </Grid>
                ))}
            </Stack>
        </Box>);
};

export default ConditionalLineComponent;
