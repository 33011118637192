import { DropdownItem } from '../components/forms/DropdownInput';
import { AnswerType, QuestionAndAnswerValues } from '../models/AnswerValues'
import jsonData from './Con29Resi_AnswerValues.json';

let conditionKeysArray: DropdownItem[] | null = null;
let conditionValuesRecord: Record<string, DropdownItem[]> | null = null;
let conditionKeys: string[] | null = null;

export const getQuestions = (): string[] => {
    if(!conditionKeys) {
        conditionKeys = [];
        jsonData.Questions.forEach((question: QuestionAndAnswerValues) => {
            conditionKeys?.push(question.Key);
        });
    }
    return conditionKeys;
};

export const getQuestionsDropDown = (): DropdownItem[] => {
    if (!conditionKeysArray) {
        conditionKeysArray = jsonData.Questions.map((question: QuestionAndAnswerValues) => ({
            key: question.Key,
            value: question.Key

        }));
    }
    return conditionKeysArray;
};

export const getAnswersDropDowns = (): Record<string, DropdownItem[]> => {
    if (!conditionValuesRecord) {
        conditionValuesRecord = {};
        jsonData.Questions.forEach((question: QuestionAndAnswerValues) => {
            if (question.Type === AnswerType.list) {
                const key = question.Key
                const answers = Array.isArray(question.Options) ? question.Options : [];
                if (answers && answers.length > 0) {

                    const answerDropdowns: DropdownItem[] = answers.map((answer: string) => ({
                        key: answer,
                            value: answer
                    }))
                    if(conditionValuesRecord) {
                    conditionValuesRecord[key] = answerDropdowns;
                }
            }
            }
        })
    }
    return conditionValuesRecord;
};