import { Alert, Box, Grid, Paper, Snackbar, Stack } from '@mui/material';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Company } from '../models/CompanyModel';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import TextInput from '../components/forms/TextInput';
import { DataModel } from '../models/DataModel';
import { useEndpoints } from '../utils/EndpointContext';

type Props = {}

const CompaniesPage: React.FC = (props: Props) => {
    const endPoints = useEndpoints();
    const itemsEndRef = useRef<null | HTMLDivElement>(null);
    const [itemAdded, setItemAdded] = useState(false);
    const [baseData, setBaseData] = useState<DataModel>();
    const [data, setData] = useState<Company[]>([]);
    const [openAlert, setOpenAlert] = useState<boolean>(false);

    useEffect(() => {
        const activeData = localStorage.getItem('activeData');

        axios.get(`${endPoints.questionEditorRead}&blobName=${activeData}`)
        .then(response => {
            setBaseData(response.data);
            setData(response.data.Companies);
        });
    }, []);

    useEffect(() => {
        if (itemAdded && itemsEndRef.current) {
          itemsEndRef.current.scrollIntoView({ behavior: 'smooth' });
          setItemAdded(false);
        }
      }, [itemAdded]);

    const handleIdChange = useCallback((value: string, index: number) => {
        const newData = [...data];
        newData[index].Id = value;
        setData(newData);
    }, [data]);

    const handleNameChange = useCallback((value: string, index: number) => {
        const newData = [...data];
        newData[index].Name = value;
        setData(newData);
    }, [data]);

    const handleAddressChange = useCallback((value: string, index: number) => {
        const newData = [...data];
        newData[index].Address = value;
        setData(newData);
    }, [data]);

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleSave = () => {
        const activeData = localStorage.getItem('activeData');
        baseData!.Companies = data;
        axios.post(`${endPoints.questionEditorUpdate}&blobName=${activeData}`, baseData);
        setOpenAlert(true);
    };

    const handleAdd = () => {
        setData([...data, { Id: '', Name: '', Address: '' }]);
        setItemAdded(true);
    };

    const handleRemove = (index: number) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
    };

    return(
        <div>
            {data ?
            <>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginBottom: '1em'}}>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color='primary' onClick={handleSave}>Save <SaveIcon /></Button> 
                                <Button variant="contained" color="secondary" onClick={handleAdd}>Create <CreateIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Stack spacing={2}>
                    {data.map((item, index) => (
                        <Paper key={index}>
                            <Stack>
                                <Grid container spacing={2} sx={{mb: "1em"}}>
                                    <Grid item xs={4}>
                                        <TextInput label={'Key'} fieldName={'key'} value={item.Id} onChange={(value) => handleIdChange(value, index)} />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <TextInput label={'Name'} fieldName={'name'} value={item.Name} onChange={(value) => handleNameChange(value, index)} />
                                    </Grid>
                                    <Grid item xs={1} className='actions-bar'>
                                        <Button color='error' onClick={() => handleRemove(index)}> <DeleteIcon /></Button>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <TextInput label={'Address'} multiline={true} fieldName={'address'} value={item.Address} onChange={(value) => handleAddressChange(value, index)} />
                        </Paper>
                    ))}
                </Stack>
                <div ref={itemsEndRef}></div>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginTop: '1em'}}>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color='primary' onClick={handleSave}>Save <SaveIcon /></Button> 
                                <Button variant="contained" color="secondary" onClick={handleAdd}>Create <CreateIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        Companies updated successfully.
                    </Alert>
                </Snackbar>
            </>
            : <>Loading Companies</>}
        </div>
    );
}

export default CompaniesPage;