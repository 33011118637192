export interface Endpoints {
  questionEditorCreate: string;
  questionEditorList: string;
  questionEditorRead: string;
  questionEditorUpdate: string;
  b2cPolicies: {
    names: {
      signUpSignIn: string,
      forgotPassword: string
    },
    authorities: {
      signUpSignIn: {
        authority: string
      },
      forgotPassword: {
        authority: string
      }
    },
    authorityDomain: string
    redirectUri: string
    clientId: string
  }
} 
  
  export const loadEndpoints = async (): Promise<Endpoints> => {
    const response = await fetch('/Endpoints.json');
    if (!response.ok) {
      throw new Error(`Failed to load configuration: ${response.status}`);
    }
    const temp = response.json();
    return temp;
  };
  